import { useEffect, useState } from 'react';
import './Auth.css';
import { auth } from '../libs/auth-users';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext/AuthContext';

function Auth(): JSX.Element {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/checkouts');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await auth(email, password, isLogin ? 'login' : 'signup');

      if (response.token !== undefined) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("refresh_token", response.refresh_token);

        setMessage('Login successful! Redirecting...');

        setIsAuthenticated(true)
        window.location.href = '/checkouts';
      } else {
        setMessage(response.data.message);
      }
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h1>Marian.<span>tel</span></h1>
      <h2>Connexion</h2>
      <form onSubmit={handleSubmit}>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label>Mot de passe</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {message && <div className="error-message">{message}</div>}
        <button type="submit">{isLogin ? 'Se connecter' : 'S\'inscrire'}</button>
      </form>
      <div onClick={() => setIsLogin(!isLogin)}>
        <span className="link">{isLogin ? `Je n'ai pas encore de compte` : `J'ai déjà un compte`}</span>
      </div>
      {loading && <div className="loading">Chargement en cours</div>}
    </div>
  );
}

export default Auth;
